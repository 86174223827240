/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/useful-knowledge/acoustic-neuroma/'], ['en', 'https://www.hear.com/useful-knowledge/acoustic-neuroma/'], ['en-US', 'https://www.hear.com/useful-knowledge/acoustic-neuroma/'], ['en-CA', 'https://ca.hear.com/useful-knowledge/acoustic-neuroma/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-diagnosis--therapy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-diagnosis--therapy",
    "aria-label": "symptoms diagnosis  therapy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms, diagnosis & therapy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Acoustic neuroma is a benign tumor that usually arises from the Schwann cells of the vestibular nerve and in rare cases also from the Schwann cells of the cochlear nerve. Hence it is also termed vestibular schwannoma. The Schwann cells build an electrical insulation layer, enveloping the nerve on the outside."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This ensures a faster transfer of the stimulus – in other words, “information” can be relayed faster via nerves. The acoustic neuroma is a very rare tumor, the incidence of which, however, increases with increasing age. Normally, it grows very slowly. Depending on its localization, two forms of the tumor are distinguished."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "two-forms-of-acoustic-neuroma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#two-forms-of-acoustic-neuroma",
    "aria-label": "two forms of acoustic neuroma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Two forms of acoustic neuroma"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Medial tumor (Tumor is located towards the middle)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This form of the acoustic neuroma is located in the so-called cerebellopontine angle, which is a very tight space between parts of the cerebellum and the pons. This small niche harbors central parts of many cranial nerves.\nAn acoustic neuroma growing in this small space can cause certain nerves to be crushed. The result is pressure damage of these nerves. Depending on which nerves are affected, there can be different symptoms present."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Lateral tumor (Tumor is located towards the side)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This form of the tumor is located in the internal auditory meatus (Meatus acusticus internus), which is a canal within the petrous part of the temporal bone. Besides the vestibular and cochlear nerves (Nervus vestibulocochlearis), this canal also channels the facial nerve (Nervus facialis) and the gustatory nerve (Chorda tympani). Again, the space-occupying growth of the acoustic neuroma can result in pressure damage of nerves and consequently varying symptoms."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-symptoms",
    "aria-label": "the symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A characteristic element of acoustic neuroma is a one-sided onset of symptoms, i.e. only one ear is affected. Basically, there are three very typical symptoms. Therefore, doctors speak of a so-called triad of symptoms. Most of the time, the first sign for the acoustic neuroma is a one-sided loss of hearing. This can either occur suddenly in the form of an acute hearing loss or slowly in the form of worsening hearing impairment. Additionally, acoustic neuroma patients often suffer from ringing in the ears (tinnitus). Moreover, there is usually a dysfunction of the vestibular apparatus and hence dizziness and nystagmus (rapid, involuntary movements of the eye). Occasionally, the acoustic neuroma causes facial pain or numbness in the jaw area.\nComplications include nausea, vomiting, and movement disorder. These symptoms only appear when the tumor has reached a sizeable mass and is crushing the brainstem."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/acoustic_neuroma.jpg",
    alt: "Acoustic neuroma",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally, the diagnosis can be confirmed by combining three different examination methods:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. The hearing evaluation")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing evaluation can consist of various methods of testing. On the one hand, we use hearing tests, such as the liminal audiometry test, whereby the patient is exposed to a number of high- and low-pitched tones at varying intensities, respectively. The patient indicates when the tone can be just barely heard. This information is graphed onto a curve of threshold levels. A liminal audiometry for an acoustic neuroma patient deviates downwards at every audio frequency. This is explained by the patient’s increasing loss of hearing. Another very important method of examination is the BERA (Brainstem Evoked Response Audiometry). The patient is connected to an EEG, which measures electrical brain activity. Simultaneously, the patient is exposed to acoustic signals, which in turn trigger changes in the EEG. This examination procedure allows us to determine exactly which part of the acoustic nerve is damaged by the acoustic neuroma."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Examination of the vestibular organ")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Acoustic neuroma patients exhibit hypo-excitability in the vestibular organ. Symptoms include dizziness and in some cases rapid, involuntary movements of the eye (nystagmus)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3. Magnetic resonance imaging (MRI)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The MRI is an imaging technique and is used to confirm an acoustic neuroma diagnosis with absolute certainty."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "therapy-of-acoustic-neuroma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#therapy-of-acoustic-neuroma",
    "aria-label": "therapy of acoustic neuroma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Therapy of acoustic neuroma"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Usually, acoustic neuromas larger than 1 inch in diameter are removed surgically, given the general condition of the patient allows it. If the tumor is smaller than 1 inch, there usually are two different approaches: The first method consists of waiting and regularly controlling the tumor until it is large enough for surgical removal. The second option is a so-called stereotactic radiosurgery. Hereby the acoustic neuroma is treated with a very high dose of radiation in order to completely destroy it."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
